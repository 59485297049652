import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import Button from '@propertypal/web-ui/src/buttons/Button';
import React from 'react';
import { useTheme } from 'styled-components';
import { SignupOptionsContainer } from './Login.style';

interface Props {
  prefix: string;
  hide: boolean;
  redirectLocation: string;
}

const SocialLogins = (props: Props) => {
  const theme = useTheme();

  const onClick = (provider: string) => async () => {
    const eventName = props.prefix === 'Login' ? 'login' : 'sign_up';

    trackGaEvent(eventName, {
      method: provider,
      form_location: 'login',
    });

    window.location.assign(`/api/auth/providers/${provider}?callbackUrl=${props.redirectLocation}`);
  };

  return (
    <SignupOptionsContainer hide={props.hide}>
      <Button
        containerStyle={{ marginBottom: 20 }}
        icon={faFacebookF}
        color={theme.facebookBlue}
        testID="facebookButton"
        onClick={onClick('facebook')}
      >
        {props.prefix} with Facebook
      </Button>

      <Button
        containerStyle={{ marginBottom: 20 }}
        icon={faGoogle}
        color={theme.googleRed}
        testID="googleButton"
        onClick={onClick('google')}
      >
        {props.prefix} with Google
      </Button>

      <Button icon={faApple} testID="appleButton" onClick={onClick('apple')} color={theme.textDark}>
        {props.prefix} with Apple
      </Button>
    </SignupOptionsContainer>
  );
};

export default SocialLogins;
