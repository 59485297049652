import React from 'react';
import CardContainer from './Card.style';

interface CardProps {
  children: React.ReactNode;
  maxWidth?: number;
}

const Card = (props: CardProps) => {
  return <CardContainer maxWidth={props.maxWidth}>{props.children}</CardContainer>;
};

export default Card;
