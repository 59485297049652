import styled from 'styled-components';

interface CardContainerProps {
  maxWidth?: number;
}

const applyProps = (props: CardContainerProps) => {
  let styles = '';
  if (props.maxWidth) styles += `max-width: ${props.maxWidth}px;`;
  return styles;
};

const CardContainer = styled.div<{ maxWidth?: number }>`
  border-radius: 6px;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  width: auto;
  background: ${(props) => props.theme.white};
  ${applyProps};
`;

export default CardContainer;
