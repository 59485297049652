/* eslint-disable max-len */
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faHeart } from '@fortawesome/pro-regular-svg-icons/faHeart';
import { faMobile } from '@fortawesome/pro-regular-svg-icons/faMobile';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import platform from '@propertypal/shared/src/utils/platform';
import IconText from '@propertypal/web-ui/src/icons/IconText';
import React from 'react';
import { LoginBenefitsContainer, LoginBenefitsTitle, LoginBenefitsWrap } from './Login.style';

const LoginBenefits = () => {
  return (
    <LoginBenefitsContainer>
      <LoginBenefitsTitle>Why Sign Up For A Free Account With {platform.title}?</LoginBenefitsTitle>
      <LoginBenefitsWrap>
        <IconText
          size={26}
          iconName={faBell}
          title="Never Miss New Listings"
          subText="Real time email alerts about the latest properties as soon as they are added."
        />
        <IconText
          size={26}
          iconName={faSearch}
          title="Save Searches"
          subText="Save the searches you use most frequently and set up alerts to see the results even faster."
        />
        <IconText
          iconName={faHeart}
          size={26}
          title="Save Properties"
          subText="Keep your favourites all under one roof and stay up to date on price and status changes."
        />
        <IconText
          iconName={faUsers}
          size={26}
          title="Share with your pals"
          subText="Property searching is always better with pals. Share your house hunting with as many pals as you like."
        />
        <IconText
          iconName={faEnvelope}
          size={26}
          title="Stay up to date"
          subText="Keep track of all your property enquiries in one easy place."
        />
        <IconText
          iconName={faMobile}
          size={26}
          title="Be Mobile"
          subText="View your favourites, saved searches and stay updated on the go, across any device."
        />
      </LoginBenefitsWrap>
    </LoginBenefitsContainer>
  );
};

export default LoginBenefits;
