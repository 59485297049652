import React from 'react';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { IconTextContainer, IconTextHeading, IconSubHeading, IconTextWrap, Icon } from './IconText.style';

export interface IconTextProps {
  title: string;
  subText?: string;
  iconName: any;
  size: number;
  color?: string;
  textColour?: string;
  textWeight?: string;
  fontSize?: string;
  italicSubtext?: boolean;
}

const IconText = ({
  title,
  subText,
  iconName,
  size,
  color,
  textColour,
  textWeight,
  italicSubtext,
  fontSize,
}: IconTextProps) => {
  return (
    <IconTextContainer>
      <Icon>
        <FontAwesomeIcon size={size} icon={iconName} color={color || '#ffffff'} />
      </Icon>
      <IconTextWrap>
        <IconTextHeading textColour={textColour} textWeight={textWeight} fontSize={fontSize}>
          {title}
        </IconTextHeading>
        {subText && (
          <IconSubHeading italicSubtext={italicSubtext} data-testid="icon-subtext">
            {subText}
          </IconSubHeading>
        )}
      </IconTextWrap>
    </IconTextContainer>
  );
};

export default IconText;
