import platform from '@propertypal/shared/src/utils/platform';
import Button from '@propertypal/web-ui/src/buttons/Button';
import Card from '@propertypal/web-ui/src/card/Card';
import HorizontalDivider from '@propertypal/web-ui/src/divider/HorizontalDivider';
import ErrorMessage from '@propertypal/web-ui/src/errors/ErrorMessage';
import Chevron from '@propertypal/web-ui/src/icons/Chevron';
import Tabs from '@propertypal/web-ui/src/tabs/Tabs';
import { Heading, Text } from '@propertypal/web-ui/src/typography';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { LoginComponentProps } from '../../types/LoginComponentProps';
import { LoginFormStyle, LoginItem, LoginCardContainer, EstateAgentLoginRow, LoginContent } from './Login.style';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import SocialLogins from './SocialLogins';

const TABS = [
  { key: 'login', text: 'Login' },
  { key: 'signup', text: 'Sign Up' },
];

const LoginFormContainer = (props: LoginComponentProps) => {
  const [revealFields, setRevealFields] = useState(false);
  const [tab, setTab] = useState(props.initialTab || 'login');
  const router = useRouter();

  return (
    <LoginItem>
      <LoginCardContainer>
        <Card>
          <Tabs tabs={TABS} activeTab={tab} onTabClick={setTab} disableArrows />

          {!!router.query.error && (
            <ErrorMessage
              errorHeader="We couldn't log you in"
              errorMessage="Please try another method or check again later"
              show
            />
          )}

          {tab === 'login' && (
            <LoginContent>
              <Heading align={platform.select('center', 'left')}>Login to {platform.title}</Heading>

              {platform.select(null, <Text>Save properties and get alerted when new ones are added.</Text>)}

              <LoginFormStyle>
                <SocialLogins hide={false} redirectLocation={props.redirectLocation} prefix="Login" />
                <HorizontalDivider mt={20} mb={20} text="or" />
                <LoginForm {...props} />
              </LoginFormStyle>
            </LoginContent>
          )}

          {tab === 'signup' && (
            <LoginContent>
              <Heading align={platform.select('center', 'left')}>Sign Up to {platform.title}</Heading>

              {platform.select(null, <Text>Save properties and get alerted when new ones are added.</Text>)}

              <LoginFormStyle>
                <SocialLogins hide={revealFields} redirectLocation={props.redirectLocation} prefix="Sign Up" />
                {!revealFields && <HorizontalDivider mt={20} mb={20} text="or" />}
                <RegisterForm
                  redirectLocation={props.redirectLocation}
                  revealFields={revealFields}
                  onRevealFields={() => setRevealFields(true)}
                />
              </LoginFormStyle>
            </LoginContent>
          )}
        </Card>

        {platform.title === 'PropertyPal' && (
          <EstateAgentLoginRow>
            <p>
              <a href="https://client.propertypal.com" target="_blank" rel="noreferrer">
                Estate Agents log in here <Chevron size={14} />
              </a>
            </p>

            <a href="https://client.propertypal.com" target="_blank" rel="noreferrer">
              <Button>Estate Agents Login</Button>
            </a>
          </EstateAgentLoginRow>
        )}
      </LoginCardContainer>
    </LoginItem>
  );
};

export default LoginFormContainer;
