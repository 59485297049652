import { tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const IconTextContainer = styled.div`
  display: flex;
  margin: 30px 0px;
  padding: 0px;
`;
export const IconTextHeading = styled.h3<{ textColour?: string; textWeight?: string; fontSize?: string }>`
  text-transform: uppercase;
  color: ${({ textColour }) => textColour || `rgb(255, 255, 255)`};
  font-weight: ${({ textWeight }) => textWeight || `600`};
  font-size: ${({ fontSize }) => fontSize || `1.3em`};
  padding: 0px;
  margin: 0px;
  line-height: 1;

  @media screen and (${tabletMax}) {
    font-size: 1.1em;
  }
`;
export const IconSubHeading = styled.h4<{ italicSubtext?: boolean }>`
  text-transform: none;
  font-weight: 300;
  color: rgb(51, 51, 51);
  padding: 0px;
  line-height: 1.63;
  font-size: 17px;
  max-width: 335px;
  margin-top: 5px;

  ${({ italicSubtext }) => (italicSubtext ? `font-style: italic;` : ``)}

  @media screen and (${tabletMax}) {
    font-size: 1em;
  }
`;

export const IconTextWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Icon = styled.div`
  width: 55px;
  flex-basis: 55px;
  display: flex;
  vertical-align: top;
  text-align: left;
  margin: 0px;
  padding: 0px;
  svg {
    width: 100%;
    height: 100%;
    max-width: 26px;
    max-height: 26px;
  }
`;
