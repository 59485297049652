import { createGlobalStyle } from 'styled-components';
import config from '../../../../app.config.json';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const getCaptchaKey = (action: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!window.grecaptcha) {
      reject(new Error('Recaptcha failed to load'));
    } else {
      window.grecaptcha.enterprise.ready(async () => {
        try {
          const recaptchaKey = await window.grecaptcha.enterprise.execute(config.recaptchaKey, { action });
          resolve(recaptchaKey);
        } catch {
          reject(new Error('Failed to execute recaptcha'));
        }
      });
    }
  });
};

const Recaptcha = createGlobalStyle`
  .grecaptcha-badge {
    visibility: visible !important;
  }
`;

export default Recaptcha;
