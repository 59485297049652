import { tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import platform from '@propertypal/shared/src/utils/platform';
import styled from 'styled-components';
import { MAX_WIDTH } from '../layout/PageWrapper.style';

export const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.backgroundLightest};
`;

export const Content = styled.div`
  max-width: ${MAX_WIDTH}px;
  margin: 0 auto;
  padding: 0 15px;
  height: ${platform.select('70px', '54px')};
  display: flex;
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Tab = styled.button<{ disabled?: boolean }>`
  display: inline-block;
  color: ${({ theme, disabled }) => (disabled ? theme.backgroundLight : theme.backgroundMid)};
  font-size: 14px;
  font-weight: 600;
  padding: 0 25px;
  transition: 200ms color;
  white-space: nowrap;

  ${({ disabled }) => disabled && `cursor: not-allowed;`};

  ${({ disabled, theme }) =>
    !disabled &&
    `
    &:hover {
      color: ${theme.textDark};
    }
  `}

  span {
    display: block;
    margin-bottom: -2px;
    pointer-events: none;
  }
`;

export const Line = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 4px;
  background-color: ${({ theme }) => theme.primary};
  transition: 200ms all;
  pointer-events: none;
`;

export const ArrowLeft = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    90deg,
    rgba(239, 239, 239, 1) 0%,
    rgba(239, 239, 239, 1) 25%,
    rgba(239, 239, 239, 0.56) 75%,
    rgba(239, 239, 239, 0) 100%
  );

  @media (${tabletMax}) {
    display: flex;
  }
`;

export const ArrowRight = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    90deg,
    rgba(239, 239, 239, 0) 0%,
    rgba(239, 239, 239, 0.56) 25%,
    rgba(239, 239, 239, 1) 75%,
    rgba(239, 239, 239, 1) 100%
  );

  @media (${tabletMax}) {
    display: flex;
  }
`;
