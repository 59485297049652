import * as yup from 'yup';
import { validPrefix } from '../utils/phoneNumber';
import { validEmail, validPhoneCharacters } from '../utils/regex';

export const signUpSchema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  email: yup
    .string()
    .matches(validEmail, 'Please enter a valid email address')
    .required('Please enter your email address'),
  prefix: yup.string().trim().test(validPrefix).required('Please include a country code'),
  phoneNumber: yup
    .string()
    .matches(validPhoneCharacters, 'Please enter a valid phone number')
    .required('Please enter your phone number'),
  pass: yup.string().min(8, 'Password must be at least 8 characters long').required('Please enter a password'),
  passwordConfirm: yup
    .string()
    .required('Enter password again')
    .oneOf([yup.ref('pass')], 'Passwords must match'),
  terms: yup.bool().oneOf([true], 'T&Cs must be accepted'),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(validEmail, 'Please enter a valid email address')
    .required('Please enter your email address'),
  pass: yup.string().required('Please enter a password'),
});
