import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import React, { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { CheckBorder, Container, HiddenInput, Label, Logo, LogoBox } from './Checkbox.style';
import ValidationError from './ValidationError';

interface Props {
  logo?: string;
  containerStyle?: CSSProperties;
  name: string;
  className?: string;
  label?: string | JSX.Element;
  onValueChange?: (value: boolean) => void;
  value: boolean;
  testID?: string;
  disabled?: boolean;
  error?: string | false;
}

const Checkbox: FunctionComponent<Props> = (props) => {
  const [checked, setChecked] = useState(props.value);

  useEffect(() => {
    // in case the value changes outside of this component
    setChecked(props.value);
  }, [props.value]);

  return (
    <div style={props.containerStyle}>
      <Container className={props.className}>
        <CheckBorder $checked={checked} $disabled={props.disabled}>
          <HiddenInput
            id={props.name}
            name={props.name}
            type="checkbox"
            defaultChecked={checked}
            onChange={() => {
              if (!props.disabled && props.onValueChange) {
                props.onValueChange(!checked);
                setChecked(!checked);
              }
            }}
            data-testid={props.testID}
            disabled={props.disabled}
            clickable={!!props.onValueChange}
          />

          {checked && <FontAwesomeIcon icon={faCheck} style={{ fontSize: 14 }} testID="check-icon" />}
        </CheckBorder>

        {!!props.label && (
          <LogoBox>
            {props.logo && <Logo src={props.logo} alt="logo" />}

            <Label htmlFor={props.name} disabled={props.disabled} clickable={!!props.onValueChange}>
              {props.label}
            </Label>
          </LogoBox>
        )}
      </Container>

      <ValidationError error={props.error} />
    </div>
  );
};

export default Checkbox;
