import countryPhoneCodes from '../constants/countryPhoneCodes.json';

export const validPrefix = {
  name: 'phone-prefix',
  test: (value: any) => {
    if (!value) return true;

    let valid = false;

    countryPhoneCodes.forEach((country) => {
      if (value === `+${country.code}`) {
        valid = true;
      }
    });

    return valid;
  },
  message: 'Please include a valid country code',
};

export const getPrefix = (phoneNumber?: string) => {
  let prefix = '+44';

  if (phoneNumber && phoneNumber.startsWith('+')) {
    countryPhoneCodes.forEach((country) => {
      if (phoneNumber.startsWith(`+${country.code}`)) {
        prefix = `+${country.code}`;
      }
    });
  }

  return prefix;
};

export const getNumberWithoutPrefix = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return '';
  }

  if (phoneNumber.startsWith('+')) {
    let pn = phoneNumber;

    countryPhoneCodes.forEach((country) => {
      if (phoneNumber.startsWith(`+${country.code}`)) {
        pn = phoneNumber.replace(`+${country.code}`, '');
      }
    });

    return pn;
  }

  return phoneNumber;
};
