import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import useEventListener from '@propertypal/shared/src/hooks/useEventListener';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { ArrowLeft, ArrowRight, Container, Content, Line, Tab } from './Tabs.style';

export interface TabType {
  key: string;
  text: string;
  prefix?: string;
  disabled?: boolean;
}

interface Props {
  tabs: TabType[];
  activeTab: string;
  onTabClick: (key: string) => void;
  disableArrows?: boolean;
}

const setLinePosition = (
  scrollRef: HTMLDivElement | null,
  targetRef: HTMLButtonElement | null,
  lineRef: HTMLDivElement | null,
) => {
  if (scrollRef && targetRef && lineRef) {
    const rect = targetRef.getBoundingClientRect();
    lineRef.style.left = `${rect.left - scrollRef.getBoundingClientRect().left + scrollRef.scrollLeft}px`;
    lineRef.style.width = `${rect.width}px`;
  }
};

const Tabs: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const activeRef = useRef<HTMLButtonElement | null>(null);
  const lineRef = useRef<HTMLDivElement | null>(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);

  const handleScroll = (e: any) => {
    setShowLeft(e.target.scrollLeft !== 0);
    setShowRight(e.target.scrollLeft < e.target.scrollWidth - e.target.offsetWidth);
  };

  const scrollToRight = () => {
    contentRef.current?.scrollTo({
      behavior: 'smooth',
      left: contentRef.current.scrollLeft + contentRef.current.getBoundingClientRect().width,
    });
  };

  const scrollToLeft = () => {
    contentRef.current?.scrollTo({
      behavior: 'smooth',
      left: contentRef.current.scrollLeft - contentRef.current.getBoundingClientRect().width,
    });
  };

  const setActiveTab = (target: HTMLButtonElement) => {
    setLinePosition(contentRef.current, target, lineRef.current);
    activeRef.current = target;

    contentRef.current?.scrollTo({
      left:
        target.getBoundingClientRect().left +
        contentRef.current.scrollLeft +
        target.getBoundingClientRect().width / 2 -
        contentRef.current.getBoundingClientRect().width / 2,
      behavior: 'smooth',
    });
  };

  const handleTabClick = (tab: TabType) => {
    if (!tab.disabled) {
      props.onTabClick(tab.key);
    }
  };

  useEventListener('resize', () => setLinePosition(contentRef.current, activeRef.current, lineRef.current));

  useEffect(() => {
    // zero timeout helps line position calculate the correct position on first load
    setTimeout(() => {
      const el = document.getElementById(`tab-${props.activeTab}`);
      // @ts-ignore
      if (el) setActiveTab(el);
    }, 0);
  }, [props.activeTab]);

  return (
    <Container>
      <Content ref={contentRef} onScroll={handleScroll}>
        {props.tabs.map((tab) => (
          <Tab
            id={`tab-${tab.key}`}
            key={tab.key}
            disabled={tab.disabled}
            data-testid="tab-button"
            onClick={() => handleTabClick(tab)}
            onMouseOver={(e) => {
              // @ts-ignore
              if (!tab.disabled) setLinePosition(contentRef.current, e.target, lineRef.current);
            }}
            onMouseOut={() => setLinePosition(contentRef.current, activeRef.current, lineRef.current)}
            style={{ color: tab.key === props.activeTab ? theme.textDark : undefined }}
          >
            {!!tab.prefix && (
              <span style={{ color: tab.key === props.activeTab ? theme.textLight : undefined }}>{tab.prefix}</span>
            )}

            {tab.text}
          </Tab>
        ))}

        <Line ref={lineRef} />
      </Content>

      {!props.disableArrows && showLeft && (
        <ArrowLeft onClick={scrollToLeft} data-testid="tab-arrow-left">
          <FontAwesomeIcon icon={faChevronLeft} color={theme.primary} style={{ fontSize: 24 }} />
        </ArrowLeft>
      )}

      {!props.disableArrows && showRight && (
        <ArrowRight onClick={scrollToRight} data-testid="tab-arrow-right">
          <FontAwesomeIcon icon={faChevronRight} color={theme.primary} style={{ fontSize: 24 }} />
        </ArrowRight>
      )}
    </Container>
  );
};

export default React.memo(Tabs);
