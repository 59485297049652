import platform from '@propertypal/shared/src/utils/platform';
import React from 'react';
import { LoginComponentProps } from '../../types/LoginComponentProps';
import { LoginContainer, LoginWrapper, LoginSection } from './Login.style';
import LoginBenefits from './LoginBenefits';
import LoginFormContainer from './LoginFormContainer';

const Login = (props: LoginComponentProps) => {
  return (
    <LoginContainer {...props}>
      <LoginWrapper>
        <LoginSection>
          <LoginFormContainer {...props} />

          {platform.select(<LoginBenefits />, null)}
        </LoginSection>
      </LoginWrapper>
    </LoginContainer>
  );
};

export default Login;
