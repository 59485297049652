import platform from '@propertypal/shared/src/utils/platform';
import styled from 'styled-components';

export const Label = styled.label`
  display: block;
  color: ${(props) => props.theme.textDark};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const InputContainer = styled.div<{ disabled?: boolean; error?: string | false }>`
  background: ${(props) => (props.disabled ? props.theme.backgroundLighter : props.theme.background)};
  height: ${platform.select(56, 46)}px;
  box-shadow: inset 0 0 0 ${(props) => (props.error ? `2px ${props.theme.primary}` : `1px ${props.theme.keyline}`)};
  padding: 2px;
  display: flex;
  flex-direction: row;
  border-radius: ${platform.select(0, 6)}px;
`;

export const Input = styled.input`
  color: ${(props) => props.theme.textDark};
  font-size: 16px;
  border: none;
  min-width: 0;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 0;
  margin-left: 10px;
  padding: 0;
`;
