import styled from 'styled-components';

const ErrorMessageContainer = styled.div<{ show: boolean }>`
  background: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.white};
  border: 0;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 30px 70px;
  animation-name: fadeInUp;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  text-align: center;

  & > h4 {
    font-weight: bold;
    font-size: 1.4em;
  }

  & > ul {
    list-style-type: disc;
    padding: 0;
    margin: 0.5em 0 0.5em 2em;
  }

  & > p {
    padding-top: 0px;
    font-size: 1.2em;
    white-space: pre-line;
    word-break: break-word;
  }

  ${(props) => (props.show ? `` : `animation-name: fadeOutDown;`)}

  @keyframes fadeOutDown {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      padding: 0;
      margin: 0;
      max-height: 0;
      transform: translate3d(0, 50px, 0);
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      padding: 0;
      margin: 0;
      max-height: 0;
      transform: translate3d(0, 50px, 0);
    }

    to {
      opacity: 1;
    }
  }
`;

export default ErrorMessageContainer;

// .ErrorMessages {
//     background: $PP-orange;
//     color: $PP-white;
//     border: 0;
//     padding: .8em 1em;
//     text-align: left;
//     font-size: 12px;
//     margin-top: 10px;
//     margin-bottom: 10px;
//     animation-duration: 0.5s;
//     animation-fill-mode: both;
//     animation-name: fadeInUp;
//   }

//   .ErrorMessages h4 {
//     font-weight: bold;
//     font-size: 1.4em;
//     margin: 0;
//   }

//   .ErrorMessages ul {
//     list-style-type: disc;
//     padding: 0;
//     margin: 0.5em 0 0.5em 2em;
//   }

//   .ErrorMessages--Centered {
//     padding-top: 30px;
//     padding-right: 70px;
//     padding-left: 70px;
//   }

//   .ErrorMessages--Centered h4 {
//     font-size: 1.4em;
//   }

//   .ErrorMessages.ErrorMessages--Centered {
//     text-align: center;
//   }

//   .ErrorMessages--Centered p {
//     padding-top: 0px;
//     font-size: 1.2em;
//     white-space: pre-line;
//     word-break: break-word;
//   }

//   .ErrorMessages--fadeOut {
//     animation-name: fadeOutDown;
//   }
