import React, { useState, useRef, useEffect } from 'react';
import ErrorMessageContainer from './ErrorMessage.style';

interface ErrorMessageProps {
  show: boolean;
  delay?: number;
  testID?: string;
  errorHeader: string;
  errorMessage: string;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const [show, setShow] = useState(props.show);
  const [hide, setHide] = useState(!props.show);
  const timeoutRef = useRef<ReturnType<typeof setInterval | any>>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.show) {
      setShow(true);
      setHide(false);
      if (ref && ref.current && ref.current.offsetTop) {
        global.window.scrollTo({
          top: ref.current.offsetTop - 100,
          left: 0,
          behavior: 'smooth',
        });
      }
      clearTimeout(timeoutRef.current);
    } else {
      setShow(false);
      timeoutRef.current = setTimeout(() => setHide(true), props.delay || 500);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [props.show]);

  if (!hide) {
    return (
      <ErrorMessageContainer data-testid={props.testID} show={show} ref={ref}>
        <h4>{props.errorHeader}</h4>
        <p>{props.errorMessage}</p>
      </ErrorMessageContainer>
    );
  }

  return null;
};

export default ErrorMessage;
